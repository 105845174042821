<template>
  <div class="staff-register">
    <region-selector v-model="form.region" @finish="regionChange"></region-selector>

    <SelectSearchCell
        title="所属商家"
        placeholder="请输入查询或选择所属商家"
        v-model="form.merchant"
        :options="merchants"
        label-key="group_name"
        id-key="group_id"
        @change="getStores">
    </SelectSearchCell>

    <SelectSearchCell
        title="所属门店"
        placeholder="请输入查询或选择所属门店"
        v-model="form.store"
        :options="stores"
        @click.native="storeClick"
        label-key="shop_name"
        id-key="group_shop_id">
    </SelectSearchCell>

    <cell-input title="姓名" v-model="form.name" placeholder="请输入姓名"></cell-input>

    <cell-input title="手机号码" type="tel" v-model="form.phone"  placeholder="请输入手机号码"></cell-input>

  </div>
</template>

<script>
import {getGroup, getGroupShop} from "@/api/register";

export default {
  name: "StaffRegister",
  data() {
    return {
      notice: '<p>1、为了您的手机提供全方面保障服务，当您的手机</p><p>2、生意外或想换机时，请根据理赔需求至理赔申请</p><p>3、面提交理赔申请。</p>',
      merchants: [],
      stores: [],
      selectedMerchant: '',
      form: {
        region: "",
        merchant: '',
        name: '',
        phone: '',
        store:''
      },
    }
  },
  mounted() {
    getGroup().then(res => {
      this.merchants = res.data
    })
  },
  watch: {
    "form.phone"(value) {
      this.form.phone=value.replace(/\D/g,'')
    },
    form:{
      handler(val) {
        this.$emit('form',val)
      },
      deep:true
    }
  },
  methods: {
    getStores(merchant) {
      if (!merchant.group_id) {
        this.stores=[]
        this.form.store={}
        return
      }
      this.selectedMerchant=merchant

      if (!this.form.region) {
        return;
      }
      this.stores=[]
      this.form.store=''

      getGroupShop({
        city:this.form.region[1].value,
        group_id:merchant.group_id
      }).then(res=>{
        this.stores=res.data
        /*[ {
          "group_shop_id": 1,
          "province": 0,
          "city": 0,
          "district": 0,
          "group_id": 180,
          "shop_name": "贵州安顺形象店",
          "fxs_id": 1
        }]*/
      });
    },
    storeClick() {
      if (!this.form.region) {
        this.$toast('请先选择区域')
        return
      }
      if (!this.stores.length) {
        this.$toast('所属商家在该城市暂无门店')
      }
    },
    regionChange() {
      this.getStores(this.selectedMerchant)
    },
    confirm() {
      if(!(/^1[3456789]d{9}$/.test(this.form.phone))){
        this.$toast('手机号')
        return false;
      }
    }
  }
}
</script>

<style scoped lang="less">

.cell-value {
  text-align: right;
}
</style>
