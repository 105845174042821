<template>
  <div class="page-container">
    <section-title>
      <div class="title-content">
        <img
          class="note-icon"
          src="@/assets/images/register/icon_ts.png"
          alt=""
        />
        <span>注意事项</span>
      </div>
    </section-title>
    <notice
      :content="notice | decodeHTML"
      :icon="require('@/assets/images/register/icon_ts.png')"
    ></notice>

    <section-title title="资料填写" show-asterisk></section-title>

    <merchant v-if="type === 'repairman'" @form="formChange"></merchant>

    <staff v-if="type === 'staff'" @form="formChange"></staff>

    <!--    <protocol v-model="isReadProtocol"></protocol>-->

    <my-button
      class="confirm-btn"
      :type="isDone ? 'primary' : 'default'"
      :disabled="!isDone"
      @click="confirm"
      >提交注册
    </my-button>
  </div>
</template>

<script>
import Merchant from "@/views/Register/Repairman";
import Staff from "@/views/Register/Staff";
import { addShopMember, setRepair } from "@/api/register";
import { article } from "@/api";
import { isFilled } from "@/utils";

export default {
  name: "index",
  components: { Merchant, Staff },
  props: {
    type: String,
  },
  data() {
    return {
      notice: "",
      form: {},
      isReadProtocol: false,
      isDone: false,
    };
  },
  mounted() {
    document.title = this.type === "repairman" ? "维修工程师注册" : "店员注册";

    article(this.type === "repairman" ? 4 : 1).then((res) => {
      this.notice = res.data && res.data.content;
    });
  },
  methods: {
    formChange(val) {
      this.form = val;
      this.isDone = Object.keys(val).every((key) => {
        return isFilled(val[key]);
      });
    },
    confirm() {
      // if (!new RegExp(/^([\u4E00-\uFA29]|[\uE7C7-\uE7F3])*$/).test(this.form.name.trim())) {
      //   this.$toast('请输入正确中文名')
      //   return;
      // }
      if (!new RegExp(/^[1][3456789][0-9]{9}$/).test(this.form.phone)) {
        this.$toast("请输入正确手机号码");
        return;
      }
      // if (!this.isReadProtocol) {
      //   this.$toast('请先阅读协议')
      //   return;
      // }
      let { userInfo } = this.$store.state;
      let { form } = this;
      let request;
      if (this.type === "staff") {
        request = addShopMember({
          openid: userInfo.openid,
          nickname: userInfo.nickname,
          headimg: userInfo.headimg,
          name: form.name,
          phone: form.phone,
          province: form.region[0].value,
          city: form.region[1].value,
          district: form.region[2].value,
          group_id: form.merchant,
          group_shop_id: form.store,
        });
      } else {
        request = setRepair({
          openid: userInfo.openid,
          nickname: userInfo.nickname,
          headimg: userInfo.headimg,
          remember_name: form.name,
          phone: form.phone,
          province: form.region[0].value,
          city: form.region[1].value,
          district: form.region[2].value,
          address: form.address,
          remanager_id: form.repairer,
          reshop_id: form.store,
          photo: form.picUrl,
          leibie: form.type,
        });
      }
      request
        .then((res) => {
          if (res && res.code) {
            if (this.type === "repairman") {
              this.$myDialog({
                type: "success",
                title: "注册成功",
                message: "请重新识别二维码！",
                confirmButtonText: "确定",
                beforeClose: (action, done) => {
                  done();
                },
              });
            } else {
              this.$myDialog({
                type: "success",
                title: "注册成功",
                message: "您现在可以去下单啦！",
                confirmButtonText: "确定",
                beforeClose: (action, done) => {
                  done();
                  this.$router.replace("/");
                },
              });
            }
          } else {
            this.$toast(res.msg || "注册失败");
          }
        })
        .catch((err) => {
          this.$toast((err && err.msg) || "注册失败");
        });
    },
  },
};
</script>

<style scoped lang="less">
.title {
  padding-left: 24px;
  margin: 40px 0 20px;
  font-weight: 800;
  font-size: 32px;
  color: #333333;
}

.star {
  color: @red;
  position: relative;
  margin-left: 5px;
}

.confirm-btn {
  width: calc(100vw - 150px);
  margin: 30px auto 0;
}

.title-content {
  display: flex;
  align-items: center;

  .note-icon {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
}
</style>
