<template>
  <div class="repairman-register">
    <cell-input title="姓名" v-model="form.name" placeholder="请输入姓名"></cell-input>

    <cell-input title="手机号码" type="tel" v-model="form.phone"  placeholder="请输入手机号码"></cell-input>

    <region-selector v-model="form.region"></region-selector>

    <get-address v-model="form.address" placeholder="请选择或手动输入地址"></get-address>

    <SelectSearchCell
        title="所属维修商"
        placeholder="请选择所属维修商"
        v-model="form.repairer"
        :options="repairers"
        @change="selectRepairer"
        label-key="manager"
        id-key="remanager_id">
    </SelectSearchCell>

    <SelectSearchCell
        title="所属门店"
        placeholder="请输入查询或选择所属门店"
        v-model="form.store"
        :options="stores"
        label-key="reshop_name"
        id-key="reshop_id">
    </SelectSearchCell>

    <field-piker
        label='维修类别'
        placeholder="请选择维修类别"
        title='维修类别选择'
        :columns='types'
        v-model="form.type"
    >
    </field-piker>

    <section-title title="门店照片"></section-title>

    <pic-uploader :src="form.picUrl" @change="url=>{form.picUrl=url}"></pic-uploader>
  </div>
</template>

<script>
import {finishType, repairManagerList, repairShopList} from "@/api/register";

export default {
  name: "RepairmanRegister",
  data() {
    return {
      notice: '<p>1、为了您的手机提供全方面保障服务，当您的手机</p><p>2、生意外或想换机时，请根据理赔需求至理赔申请</p><p>3、面提交理赔申请。</p>',
      repairers: [],
      stores: [],
      types: [],
      form: {
        name: '',
        phone: '',
        region: '',
        address: '',
        repairer: '',
        store: '',
        type: '',
        picUrl: ''
      },
      picBeforeUpload: [],
      isShowTypePicker: false
    }
  },
  watch: {
    "form.phone"(value) {
      this.form.phone = value.replace(/\D/g, '')
    },
    form: {
      handler(val) {
        this.$emit('form', val)
      },
      deep: true
    }
  },
  mounted() {
    repairManagerList().then(res => {
      this.repairers = res.data;
    })
    finishType().then(res => {
      this.types = res.data
    })
  },
  methods: {
    selectRepairer(repairer) {
      this.stores = [];
      this.form.store = {}

      repairShopList(repairer.remanager_id).then(res => {
        this.stores = res.data||[]
      });
    },
    selectType(value) {
      this.form.type = value
      this.isShowTypePicker = false
    },
  }
}
</script>

<style scoped lang="less">
.store-picture-title {
  font-size: 40px;
  font-weight: 800;
  color: #333333;
  margin: 30px 0 30px 24px;
}
</style>
<style lang="less">
.van-uploader__preview-image {
  img {
    border-radius: 20px;
  }
}
.van-uploader__preview{
  margin: 0;
}
</style>
